import { nanoid } from 'nanoid';

// HERO DATA
export const heroData = {
  title: '',
  name: 'Isthi',
  subtitle: "I'm a software engineer, based in Melbourne, Australia",
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'isthi.png',
  paragraphOne:
    "This is a place containing things I've done and been working on lately.\n" +
    'Some of these things are going to come back and give me nightmares later on... 😆\n',
  paragraphTwo:
    'I was born in Sri Lanka. I live in Melbourne and work as a Software Engineer. I’ve been a professional engineer since 2018.\n' +
    'I’ve mostly worked professionally with Java, ReactJs and Python but also a bit of GoLang,\n' +
    '\n' +
    'I enjoy building new stuff, fixing things and working with other people – nothing really interesting is ever built alone!',
  paragraphThree: '',
  resume: '',
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'enasethumb.png',
    title: 'GoSecure: Securing Projects with Go',
    info: 'Completed a research project at RMIT University sponsored by Shine Solutions.',
    info2:
      'The paper was on the topic of an automated solution for\n' +
      'security vulnerability scanning of Google Cloud Platform (GCP) projects,\n' +
      'to cover gaps in the capabilities of solutions to scan GCP projects for common security issues.\n' +
      'The elaborated security inspection tool, GoSecure, can scan multiple GCP instances against industry recognised Center for Internet Security (CIS) benchmarks for GCP. GoSecure covers all categories listed under the CIS benchmarks for GCP, ' +
      'providing an overview of the existing security profile of all GCP projects, along with suggestions for improvement in configurations for the individual projects.',
    url: 'https://www.scitepress.org/Link.aspx?doi=10.5220/0009591605870594',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: "If you think you need my help, a project you'd like to get started, or just to say hiii, then get in touch...",
  btn: 'Hit me up',
  email: 'contact@isthi.xyz',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://twitter.com/isthi_irfan',
    },
    {
      id: nanoid(),
      name: 'instagram',
      url: 'https://www.instagram.com/isthi.irfan/',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'http://linkedin.com/in/isthi-irfan',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/isthiiii',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};

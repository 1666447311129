import React, { useState, useEffect } from 'react';
import Hero from './Hero/Hero';
import About from './About/About';
import Projects from './Projects/Projects';
import Contact from './Contact/Contact';
import Footer from './Footer/Footer';
import './app.scss';
import { PortfolioProvider } from '../context/context';

import { heroData, aboutData, projectsData, contactData, footerData } from '../mock/data';

function App() {
  const [hero, setHero] = useState({});
  const [about, setAbout] = useState({});
  const [projects, setProjects] = useState([]);
  const [contact, setContact] = useState({});
  const [footer, setFooter] = useState({});

  useEffect(() => {
    setHero({ ...heroData });
    setAbout({ ...aboutData });
    setProjects([...projectsData]);
    setContact({ ...contactData });
    setFooter({ ...footerData });
  }, []);

  return (
    <PortfolioProvider value={{ hero, about, projects, contact, footer }}>
      <Hero />
      <About />
      <Projects />
      <Contact />
      <section>
        <div className="flex-container_socials">
          <div className="video-responsive">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/Xb3QzYVgPu8"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          <div className="flex-container_socials">
            <div style={{ padding: `15px` }}>
              <iframe
                src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:6904733222039646208"
                height="400"
                width="350"
                frameBorder="0"
                allowFullScreen=""
                title="Embedded post"
              />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div
          className="flex-container_socials"
          // style={{ display: `flex`, justifyContent: `space-around`, alignItems: `center` }}
        >
          <div className="flex-container_socials">
            <a
              className="twitter-timeline"
              data-width="500"
              data-height="500"
              data-theme="light"
              href="https://twitter.com/isthi_irfan?ref_src=twsrc%5Etfw"
            >
              Tweets by isthi_irfan
            </a>
          </div>
          <div className="flex-container_socials">
            <iframe
              height="500"
              width="300"
              frameBorder="0"
              title="Strava"
              allowTransparency="true"
              scrolling="no"
              src="https://www.strava.com/athletes/57754857/latest-rides/6b900ccd2614a5c2d4cc7e07666d8ad11f456b6f"
            />
          </div>
          <div className="flex-container_socials">
            <div
              className="tagembed-container"
              style={{ width: `350px`, height: `500px`, overflow: `auto` }}
            >
              <div
                className="tagembed-socialwall"
                data-wall-id="28421"
                view-url="https://widget.tagembed.com/28421?view"
              />
            </div>
          </div>
        </div>
      </section>
      <Footer />;
    </PortfolioProvider>
  );
}

export default App;

import React from 'react';
import { Helmet } from 'react-helmet';
import App from '../components/App';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';
import Isthi from '../images/isthi.png';

export default () => (
  <>
    <Helmet>
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-WX8VQMHZN3" />
      <script>
        {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('config', 'G-WX8VQMHZN3');
      `}
      </script>
      <script>
        {`
          gtag('event', 'page_view', {
          page_title: 'Isthi.xyz',
          page_location: 'home',
          page_path: '/',
          send_to: 'G-WX8VQMHZN3'
          })
        `}
      </script>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width,initial-scale=1" />
      <title>Isthi Irfan</title>
      <html lang="en" />
      <meta name="description" content="Software Engineer in Melbourne, Australia" />
      <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8" />
      <script src="//widget.tagembed.com/embed.min.js" type="text/javascript" />
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org/",
            "@type": "Person",
            "name": "Isthi Irfan",
            "url": "http://isthi.xyz",
            "image": "${Isthi}",
            "sameAs": [
              "https://github.com/isthiiii",
              "https://www.instagram.com/isthi.irfan/",
              "https://www.linkedin.com/in/isthi-irfan/",
              "https://dev.to/isthiiii",
              "https://twitter.com/isthi_irfan"
            ],
            "jobTitle": "Software Engineer",
            "worksFor": {
               "@type": "Organization",
                "name": "MessageMedia"
              }
           }
        `}
      </script>
      <meta name="title" content="Isthi Irfan: Software Engineer in Melbourne, Australia" />
      <meta
        name="description"
        content="Isthi Irfan's profile showcasing what he's been up to lately and the projects he's been working on."
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://isthi.xyz/" />
      <meta property="og:title" content="Isthi Irfan: Software Engineer in Melbourne, Australia" />
      <meta property="og:image" content={Isthi} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://isthi.xyz/" />
      <meta
        property="twitter:title"
        content="Isthi Irfan: Software Engineer in Melbourne, Australia"
      />
      <meta
        property="twitter:description"
        content="Isthi Irfan's profile showcasing what he's been up to lately and the projects he's been working on."
      />
    </Helmet>
    <App />
  </>
);
